<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <DataTable :value="exceltemplatesetlist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows :scrollHeight="sHeight" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="50" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage($event)">
            <template #header>
                <div class="grid">
                    <div class="col-8">
                        <div class="text-left">
                            <InputText id='searchTemplateNameInput' placeholder="模板名称" v-model="searchTemplateName"
                                type="text" />
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="新建" icon="pi pi-plus" @click="createdetail()"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="删除" icon="pi pi-times" @click="deldetail()" class="button-danger" />
                        </div>
                    </div>
                </div>
            </template>
            <Column field="name" header="模板名称" style="width: 30%" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.name" @click="opendetail(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
            <Column field="sysname" header="系统" style="width: 20%"></Column>
            <Column field="modulename" header="模块" style="width: 25%"></Column>
            <Column field="templatetypename" header="模板类型" style="width: 10%"></Column>
            <Column header="操作" style="width:15%">
                <template #body="slotProps">
                    <Button v-if="slotProps.data['templatetypeid']!='2'" label="测试导出"
                        @click="exceltemplatesetTest(slotProps.data)" :loading="downLoading"
                        class="p-button-link" />&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button v-if="slotProps.data['templatetypeid']=='2' || slotProps.data['templatetypeid']=='3'"
                        label="导入" @click="exceltemplatesetImport(slotProps.data)" :loading="downLoading"
                        class="p-button-link" />
                </template>
            </Column>
        </DataTable>
    </div>
    <Dialog header="新建模板配置" v-model:visible="createDisplay" :style="{width: '70vw'}" :maximizable="true" :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="createtemplateName">模板名称</label>
                    <InputText id='createtemplateName' v-model="currentRow.name" type="text" />
                </div>
                <div class="field col">
                    <label for="createsysappidDropdown">系统</label>
                    <Dropdown id='createsysappidDropdown' v-model="currentRow.sysappid" :options="dicts['sys']"
                        optionLabel="name" optionValue="code" showClear="true" :filter="true" placeholder="" />
                </div>
                <div class="field col">
                    <label for="createmoduleappidDropdown">模块</label>
                    <Dropdown id='createmoduleappidDropdown' v-model="currentRow.moduleappid" :options="dicts['module']"
                        optionLabel="name" optionValue="code" showClear="true" :filter="true" placeholder="" />
                </div>
                <div class="field col">
                    <label for="createtemplatetypeidDropdown">类型</label>
                    <Dropdown id='createtemplatetypeidDropdown' v-model="currentRow.templatetypeid"
                        :options="dicts['ExcelTemplateType']" optionLabel="name" optionValue="code" showClear="true"
                        :filter="false" placeholder="" />
                </div>
            </div>
            <div class="field">
                <label for="createtemplateFileId">上传文件</label>
                <FileUpload mode="basic" id="createtemplateFileId" name="templateFile" :url="uploadurl" accept=".xlsx"
                    :maxFileSize="20971520" @upload="onUpload" @before-upload="beforeUpload" :auto="true"
                    invalidFileSizeMessage="{0}: 文件大小必须小于20M." invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件"
                    chooseLabel="请选择上传EXCEL模板" />
            </div>
            <h5>配置详细</h5>
            <div class="card">
                <DataTable :value="currentRow.labelinfo" :lazy="false" v-model:selection="selectedLabelinfo"
                    selectionMode="single" editMode="cell" @cell-edit-complete="onCellEditComplete"
                    @cell-edit-init="labelinfoTableCellEditInit" class="editable-cells-table" dataKey="id"
                    :scrollable="true" stripedRows scrollHeight="320px" scrollDirection="both">
                    <Column field="elabel.labelname" header="EXCEL标签" style="flex-grow:1; flex-basis:200px" frozen>
                    </Column>
                    <Column field="schemaname" header="绑定数据源" style="flex-grow:1; flex-basis:200px">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['schemaname']" @change="labelSchemanameChange"
                                :options="dicts['allschemaname']" showClear="true" :filter="true" optionLabel="name"
                                optionValue="code" style="width:120px" placeholder="请选择" />
                        </template>
                    </Column>
                    <Column field="binddata" header="绑定属性" style="flex-grow:1; flex-basis:300px">
                        <template #body="slotProps">
                            {{analysisBindData(slotProps.data['binddata'])}}
                        </template>
                        <template #editor="{ data }">
                            <TreeSelect v-model="data['binddata']['selectedValue']" @node-select="treeNodeSelect(data)"
                                :options="binddataNodes" selectionMode="single" style="width:280px" placeholder="请选择" />
                        </template>
                    </Column>
                    <Column field="ismultirow" header="多行" style="flex-grow:1; flex-basis:60px">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['ismultirow']" :options="dicts['boolDict']" optionLabel="name"
                                optionValue="code" showClear="true" placeholder="" />
                        </template>
                        <template #body="slotProps">
                            {{formatDict('boolDict',slotProps.data['ismultirow'])}}
                        </template>
                    </Column>
                    <Column field="binddatatype" header="绑定类型" style="flex-grow:1; flex-basis:120px">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['binddatatype']" :options="dicts['ExcelBindDataType']"
                                optionLabel="name" optionValue="code" showClear="true" placeholder="" />
                        </template>
                        <template #body="slotProps">
                            {{formatDict('ExcelBindDataType',slotProps.data['binddatatype'])}}
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.startrow" header="起始行" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['startrow']" type="text" />
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.endrow" header="结束行" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['endrow']" type="text" />
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.startcol" header="起始列" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['startcol']" type="text" />
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.endcol" header="结束列" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['endcol']" type="text" />
                        </template>
                    </Column>
                    <Column field="foreignkeysource" header="外键源" style="flex-grow:1; flex-basis:160px">
                        <template #editor="{ data }">
                            <InputText v-model="data['foreignkeysource']" type="text" />
                        </template>
                    </Column>
                    <Column field="foreignkeysourcename" header="外键源类别" style="flex-grow:1; flex-basis:200px">
                        <template #editor="{ data }">
                            <InputText v-model="data['foreignkeysourcename']" type="text" />
                        </template>
                    </Column>
                    <Column header="操作" style="flex-grow:1; flex-basis:100px">
                        <template #body="slotProps">
                            <Button label="移除" @click="removesavetemplateset(slotProps.data)" class="p-button-link" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeEdit" />
                <Button label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="editDisplay" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="templateName">模板名称</label>
                    <InputText id='templateName' v-model="currentRow.name" type="text" />
                </div>
                <div class="field col">
                    <label for="sysappidDropdown">系统</label>
                    <Dropdown id='sysappidDropdown' v-model="currentRow.sysappid" :options="dicts['sys']"
                        optionLabel="name" optionValue="code" showClear="true" :filter="true" placeholder="" />
                </div>
                <div class="field col">
                    <label for="moduleappidDropdown">模块</label>
                    <Dropdown id='moduleappidDropdown' v-model="currentRow.moduleappid" :options="dicts['module']"
                        optionLabel="name" optionValue="code" showClear="true" :filter="true" placeholder="" />
                </div>
                <div class="field col">
                    <label for="templatetypeidDropdown">类型</label>
                    <Dropdown id='templatetypeidDropdown' v-model="currentRow.templatetypeid"
                        :options="dicts['ExcelTemplateType']" optionLabel="name" optionValue="code" showClear="true"
                        :filter="false" placeholder="" />
                </div>
            </div>
            <h5>配置详细</h5>
            <div class="card">
                <DataTable :value="currentRow.labelinfo" :lazy="false" v-model:selection="selectedLabelinfo"
                    selectionMode="single" editMode="cell" @cell-edit-complete="onCellEditComplete"
                    @cell-edit-init="labelinfoTableCellEditInit" class="editable-cells-table" dataKey="id"
                    :scrollable="true" stripedRows scrollHeight="320px" scrollDirection="both">
                    <template #header>
                        <div class="grid">
                            <div class="col-2">
                                <div class="text-right">
                                    <Button label="标签刷新" icon="pi pi-refresh" @click="freshExcelLabe()"
                                        class="button-danger" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="elabel.labelname" header="EXCEL标签" style="flex-grow:1; flex-basis:200px" frozen>
                    </Column>
                    <Column field="schemaname" header="绑定数据源" style="flex-grow:1; flex-basis:200px">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['schemaname']" @change="labelSchemanameChange"
                                :options="dicts['allschemaname']" showClear="true" :filter="true" optionLabel="name"
                                optionValue="code" style="width:120px" placeholder="请选择" />
                        </template>
                    </Column>
                    <Column field="binddata" header="绑定属性" style="flex-grow:1; flex-basis:300px">
                        <template #body="slotProps">
                            {{analysisBindData(slotProps.data['binddata'])}}
                        </template>
                        <template #editor="{ data }">
                            <TreeSelect v-model="data['binddata']['selectedValue']" @node-select="treeNodeSelect(data)"
                                :options="binddataNodes" selectionMode="single" style="width:280px" placeholder="请选择" />
                        </template>
                    </Column>
                    <Column field="ismultirow" header="多行" style="flex-grow:1; flex-basis:60px">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['ismultirow']" :options="dicts['boolDict']" optionLabel="name"
                                optionValue="code" showClear="true" placeholder="" />
                        </template>
                        <template #body="slotProps">
                            {{formatDict('boolDict',slotProps.data['ismultirow'])}}
                        </template>
                    </Column>
                    <Column field="binddatatype" header="绑定类型" style="flex-grow:1; flex-basis:120px">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['binddatatype']" :options="dicts['ExcelBindDataType']"
                                optionLabel="name" optionValue="code" showClear="true" placeholder="" />
                        </template>
                        <template #body="slotProps">
                            {{formatDict('ExcelBindDataType',slotProps.data['binddatatype'])}}
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.startrow" header="起始行" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['startrow']" type="text" />
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.endrow" header="结束行" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['endrow']" type="text" />
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.startcol" header="起始列" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['startcol']" type="text" />
                        </template>
                    </Column>
                    <Column field="elabel.labellocation.endcol" header="结束列" style="flex-grow:1; flex-basis:70px">
                        <template #editor="{ data }">
                            <InputText v-model="data['elabel']['labellocation']['endcol']" type="text" />
                        </template>
                    </Column>
                    <Column field="foreignkeysource" header="外键源" style="flex-grow:1; flex-basis:160px">
                        <template #editor="{ data }">
                            <InputText v-model="data['foreignkeysource']" type="text" />
                        </template>
                    </Column>
                    <Column field="foreignkeysourcename" header="外键源类别" style="flex-grow:1; flex-basis:200px">
                        <template #editor="{ data }">
                            <InputText v-model="data['foreignkeysourcename']" type="text" />
                        </template>
                    </Column>
                    <Column header="操作" style="flex-grow:1; flex-basis:100px">
                        <template #body="slotProps">
                            <Button label="移除" @click="removesavetemplateset(slotProps.data)" class="p-button-link" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeEdit" />
                <Button label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="上传导入数据" v-model:visible="importDisplay" :style="{width: '30vw'}" :maximizable="true" :modal="true">
        <div class="card p-fluid">
            <div class="fluid formgrid grid">
                <div class="field">
                    <label for="importtemplateFileId">上传文件</label>
                    <FileUpload mode="basic" id="importtemplateFileId" name="importtemplateFile" :url="uploadurl"
                        accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload"
                        @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                        invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
                </div>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport" />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../../js/Common.js';
    import MessageTip from '../../components/Message';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm,
            };
        },
        data() {
            return {
                selectedLabelinfo: ref(),
                binddataNodes: ref(),
                newId: 0,
                uploadurl: '',
                importTemplateId: '',
                allSchemaName: [],
                selectedData: ref(),
                searchTemplateName: ref(),
                currentRow: null,
                loading: false,
                butLoading: false,
                downLoading: false,
                editDisplay: false,
                createDisplay: false,
                importDisplay: false,
                recordsubject: '',
                totalRecords: 0,
                first: 0,
                exceltemplatesetlist: [],
                allFields: [],
                dicts: {
                    boolDict: [{
                        name: '是',
                        code: '1'
                    }, {
                        name: '否',
                        code: '0'
                    }],
                },
                sHeight: '650px',
            }
        },
        created() {
            MessageTip.initMsg();
            this.sHeight = (window.innerHeight - 300) + "px";
            this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
            this.loadDict();
            this.loadData(1);
        },
        methods: {
            getNewId() {
                console.log('getNewId:' + this.newId);
                this.newId = this.newId + 1;
                return -1 * this.newId + '';
            },
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'templatename',
                        value: this.searchTemplateName,
                        operation: '',
                    }, {
                        name: 'labelname',
                        value: '',
                        operation: '',
                    }, {
                        name: 'sysappId',
                        value: '',
                        operation: '',
                    }, {
                        name: 'moduleappid',
                        value: '',
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{exceltemplatesetlist(where:" + JSON.stringify(listwhere) +
                    "){id sysname modulename templatetypename templatetypeid name allexclelabel {labelname labellocation {startrow startcol endrow endcol rowspan}} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.exceltemplatesetlist = jsonData.data.exceltemplatesetlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'Categories',
                        value: 'ExcelBindDataType',
                        operation: '',
                    }, {
                        name: 'Categories',
                        value: 'allschemaname',
                        operation: '',
                    }, {
                        name: 'Categories',
                        value: 'sys',
                        operation: '',
                    }, {
                        name: 'Categories',
                        value: 'module',
                        operation: '',
                    }, {
                        name: 'Categories',
                        value: 'ExcelTemplateType',
                        operation: '',
                    }]
                };
                var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                    '){name details {name code}}}';
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        for (var i = 0; i < dataObj.dictionarylist.length; i++) {
                            var dictName = dataObj.dictionarylist[i].name;
                            console.log(dictName);
                            this.dicts[dictName] = dataObj.dictionarylist[i].details;
                        }
                        console.log(this.dicts);
                    } else {
                        MessageTip.warnmsg('字典数据加载失败');
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                console.log(page);
                this.loadData(page + 1);
            },
            formatDict(v_dictName, value) {
                var name = value;
                var isFind = false;
                if (this.dicts[v_dictName]) {
                    for (var i = 0; i < this.dicts[v_dictName].length; i++) {
                        if (this.dicts[v_dictName][i].code == value) {
                            name = this.dicts[v_dictName][i].name;
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    return name;
                } else {
                    return '';
                }
            },
            opendetail(v_row) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'id',
                        value: v_row.id,
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{exceltemplatesetlist(where:" + JSON.stringify(listwhere) +
                    "){id sysname sysappid modulename moduleappid templatetypeid templatetypename filepatch name allexclelabel {labelname labellocation {startrow startcol endrow endcol rowspan}} labelinfo {id parentid schemaname elabel {labelname labellocation {startrow startcol endrow endcol rowspan}} binddatatype ismultirow foreignkeysource foreignkeysourcename binddata {key fieldname schemaname child {key fieldname schemaname child {key fieldname schemaname}}} } }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.currentRow = jsonData.data.exceltemplatesetlist[0];
                        for (var i = 0; i < this.currentRow.labelinfo.length; i++) {
                            var keyValue = this.currentRow.labelinfo[i].binddata.key;
                            var childObj = this.currentRow.labelinfo[i].binddata['child'];
                            if (childObj && childObj['key'] && childObj['key'] != '') {
                                keyValue = childObj['key'];
                                childObj = childObj['child'];
                                if (childObj && childObj['key'] && childObj['key'] != '') {
                                    keyValue = childObj['key'];
                                }
                            }
                            if (keyValue && keyValue != '') {
                                this.currentRow.labelinfo[i].binddata['selectedValue'] = {};
                                this.currentRow.labelinfo[i].binddata['selectedValue'][keyValue] = true;
                            }
                        }
                        this.selectedLabelinfo = ref();
                        this.recordsubject = v_row.sysname + v_row.modulename + v_row.name;
                        this.editDisplay = true;
                        console.log(this.currentRow);
                    }
                });
            },
            createdetail() {
                var row = {
                    id: '-1',
                    name: '',
                    sysname: '',
                    sysappid: '',
                    modulename: '',
                    moduleappid: '',
                    templatetypeid: '',
                    labelinfo: [],
                    allexclelabel: [],
                };
                this.selectedLabelinfo = ref();
                this.currentRow = row;
                this.recordsubject = '新建';
                this.createDisplay = true;
            },
            deldetail() {
                console.log(this.selectedData);
                if (this.selectedData['id']) {
                    this.loading = true;
                    var id = this.selectedData['id'];
                    var title = this.selectedData['sysname'] + this.selectedData['modulename'] + this.selectedData[
                        'name'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{exceltemplateset(o:' + JSON.stringify(this
                                    .selectedData) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.exceltemplatesetlist.length; i++) {
                                        if (this.exceltemplatesetlist[i]['id'] == id) {
                                            this.exceltemplatesetlist.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                }
            },
            closeEdit() {
                this.closeButLoading();
                this.createDisplay = false;
                this.editDisplay = false;
            },
            submitData() {
                if (this.currentRow) {
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem(
                            "appkey"),
                        'mutation{exceltemplateset(o:' + JSON.stringify(this
                            .currentRow) +
                        '){id}}'
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.currentRow = null;
                            this.loadData(1);
                            this.closeEdit();
                            MessageTip.successmsg('保存成功');
                        } else {
                            this.closeButLoading();
                            MessageTip.errmsg('保存失败');
                        }
                    });
                }
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            onUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    var listwhere = {
                        pageindex: 1,
                        pagesize: 50,
                        conditions: [{
                            name: 'filepatch',
                            value: retObj.patch,
                            operation: ''
                        }]
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{exceltemplatesetlabellist(where:' + JSON.stringify(listwhere) +
                        '){label startrow startcol endrow endcol} }'
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.currentRow.filepatch = retObj.patch;
                            var labelList = JSON.parse(unescape(Base64.decode(res.data))).data
                                .exceltemplatesetlabellist;
                            for (var i = 0; i < labelList.length; i++) {
                                this.currentRow.allexclelabel.push({
                                    labelname: labelList[i].label,
                                    labellocation: {
                                        startrow: labelList[i].startrow,
                                        startcol: labelList[i].startcol,
                                        endrow: labelList[i].endrow,
                                        endcol: labelList[i].endcol,
                                    }
                                });
                                this.currentRow.labelinfo.push({
                                    id: this.getNewId(),
                                    parentid: '-1',
                                    schemaname: '',
                                    binddatatype: 0,
                                    elabel: {
                                        labelname: labelList[i].label,
                                        labellocation: {
                                            startrow: labelList[i].startrow,
                                            startcol: labelList[i].startcol,
                                            endrow: labelList[i].endrow,
                                            endcol: labelList[i].endcol,
                                        }
                                    },
                                    binddata: {
                                        selectedValue: ref(),
                                        key: '',
                                        fieldname: '',
                                        schemaname: '',
                                    }
                                });
                            }
                        } else {
                            MessageTip.warnmsg('Excel标签分析失败');
                        }
                    });
                } else {
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            onImportUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/ExcelTemplateImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            templateid: this.importTemplateId,
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        this.importDisplay = false;
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            MessageTip.successmsg('数据导入成功');
                        }
                    });
                } else {
                    this.importDisplay = false;
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '4');
                event.formData.append('attachttype', '4');
            },
            beforeImportUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '1');
                event.formData.append('attachttype', '1');
            },
            treeNodeSelect(v_data) {
                setTimeout(() => {
                    console.log('treeNodeSelect:');
                    console.log(v_data);
                    if (v_data.binddata.selectedValue) {
                        var originalselectedValue = v_data.binddata.selectedValue;
                        console.log('treeNodeSelect2');
                        console.log(originalselectedValue);
                        for (var proname in v_data.binddata.selectedValue) {
                            v_data.binddata = this.setBindDataDetail(proname, v_data.schemaname);
                        }
                        v_data.binddata['selectedValue'] = originalselectedValue;
                    }
                    console.log(v_data.binddata);
                }, 50);
            },
            analysisBindData(v_data) {
                console.log('analysisBindData:');
                console.log(v_data);
                var bindDataStr = v_data['fieldname']
                if (v_data['child'] && v_data['child']['fieldname']) {
                    bindDataStr = bindDataStr + "." + v_data['child']['fieldname'];
                    var childObj = v_data['child']['child'];
                    if (childObj && childObj['fieldname']) {
                        bindDataStr = bindDataStr + "." + childObj['fieldname'];
                    }
                }
                return bindDataStr;
            },
            removesavetemplateset(v_data) {
                for (var i = 0; i < this.currentRow.labelinfo.length; i++) {
                    if (this.currentRow.labelinfo[i].id == v_data.id) {
                        this.currentRow.labelinfo.splice(i, 1);
                        break;
                    }
                }
            },
            labelinfoTableCellEditInit(event) {
                if (event.field && event.field == 'binddata') {
                    this.binddataNodes = ref();
                    if (event.data['schemaname'] != '') {
                        this.getBindDataDict(event.data['schemaname']);
                    }
                }
            },
            labelSchemanameChange(event) {
                this.binddataNodes = ref();
                this.getBindDataDict(event.value);
                for (var i = 0; i < this.currentRow.labelinfo.length; i++) {
                    if (this.currentRow.labelinfo[i]['schemaname'] == '') {
                        this.currentRow.labelinfo[i]['schemaname'] = event.value;
                    }
                }
            },
            getBindDataDict(v_schemaname) {
                console.log('getBindDataDict:' + v_schemaname);
                if (v_schemaname && v_schemaname != '') {
                    console.log('getBindDataDict:--1');
                    var dictSource = this.findSchemanameStructure(v_schemaname);
                    if (!dictSource) {
                        var listwhere = {
                            pageindex: 1,
                            pagesize: 50,
                            conditions: [{
                                name: 'id',
                                value: v_schemaname,
                                operation: ''
                            }]
                        };
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            '{schemastructurelist(where:' + JSON.stringify(listwhere) +
                            '){id key label data {objname objcname objtype} children {id key label data {objname objcname objtype} children {id key label data {objname objcname objtype} children {id key label data {objname objcname objtype}} } } } }'
                        ).then(res => {
                            if (res.errcode == "0") {
                                var moduleObj = JSON.parse(unescape(Base64.decode(res.data))).data
                                    .schemastructurelist;
                                this.allFields.push({
                                    schemaname: v_schemaname,
                                    data: moduleObj
                                });
                                this.binddataNodes = moduleObj;
                                console.log('getBindDataDict5:' + this.binddataNodes);
                            } else {
                                this.binddataNodes = ref();
                                MessageTip.warnmsg('类型加载失败');
                            }
                        });
                    } else {
                        this.binddataNodes = dictSource;
                    }
                    console.log('getBindDataDict3:' + dictSource);
                } else {
                    console.log('getBindDataDict:--2');
                    MessageTip.warnmsg('请先选择数据源');
                    this.binddataNodes = ref();
                }
            },
            findSchemanameStructure(v_schemaname) {
                var dictSource = null;
                for (var i = 0; i < this.allFields.length; i++) {
                    if (this.allFields[i]['schemaname'] == v_schemaname) {
                        dictSource = this.allFields[i]['data'];
                        break;
                    }
                }
                return dictSource;
            },
            setBindDataDetail(v_key, v_schemaname) {
                console.log('setBindDataDetail-1:' + v_key + '....' + v_schemaname);
                var keyArry = v_key.split('-');
                var binddataObj = {};
                var schemanameStructure = this.findSchemanameStructure(v_schemaname);
                console.log(schemanameStructure);
                for (var i = 0; i < schemanameStructure.length; i++) {
                    var key1 = keyArry[0];
                    if (schemanameStructure[i]['key'] == key1) {
                        binddataObj['key'] = schemanameStructure[i]['key'];
                        binddataObj['fieldname'] = schemanameStructure[i]['label'];
                        binddataObj['schemaname'] = v_schemaname;
                        var childrenObj1 = schemanameStructure[i]['children'];
                        if (childrenObj1 && childrenObj1.length > 0 && keyArry.length > 1) {
                            for (var j = 0; j < childrenObj1.length; j++) {
                                var key2 = keyArry[1];
                                if (childrenObj1[j]['key'] == key1 + '-' + key2) {
                                    binddataObj['child'] = {
                                        key: childrenObj1[j]['key'],
                                        fieldname: childrenObj1[j]['label'],
                                        schemaname: schemanameStructure[i]['label'],
                                    };
                                    var childrenObj2 = childrenObj1[j]['children'];
                                    if (childrenObj2 && childrenObj2.length > 0 && keyArry.length > 2) {
                                        for (var n = 0; n < childrenObj2.length; n++) {
                                            var key3 = keyArry[2];
                                            if (childrenObj2[n]['key'] == key1 + '-' + key2 + '-' + key3) {
                                                binddataObj['child']['child'] = {
                                                    key: childrenObj2[n]['key'],
                                                    fieldname: childrenObj2[n]['label'],
                                                    schemaname: childrenObj1[j]['label'],
                                                };
                                                break;
                                            }
                                        }
                                    }
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
                console.log('setBindDataDetail-2:');
                console.log(binddataObj);
                return binddataObj;
            },
            freshExcelLabe() {
                if (this.currentRow) {
                    for (var j = 0; j < this.currentRow.allexclelabel.length; j++) {
                        var isFind = false;
                        for (var i = 0; i < this.currentRow.labelinfo.length; i++) {
                            if (this.currentRow.labelinfo[i].elabel.labelname == this.currentRow.allexclelabel[j]
                                .labelname) {
                                isFind = true;
                                break;
                            }
                        }
                        if (!isFind) {
                            this.currentRow.labelinfo.push({
                                id: this.getNewId(),
                                parentid: this.currentRow.id,
                                schemaname: '',
                                binddatatype: 0,
                                elabel: this.currentRow.allexclelabel[j],
                                binddata: {
                                    selectedValue: ref(),
                                    key: '',
                                    fieldname: '',
                                    schemaname: '',
                                }
                            });
                        }
                    }
                }
            },
            exceltemplatesetTest(v_data) {
                console.log(v_data);
                this.downLoading = true;
                requests.plat_requests.post(
                    '../calf/plat/ExcelTemplateTest?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        templateid: v_data.id,
                    }
                ).then(res => {
                    if (res.errcode == "0") {
                        this.downLoading = false;
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        location.href = '../calf/plat/file/' + retObj.filepatch + '?appkey=' +
                            sessionStorage.getItem("appkey");
                    } else {
                        this.downLoading = false;
                        MessageTip.errmsg('生成Excel文件失败');
                    }
                });
            },
            closeImport() {
                this.importTemplateId = '';
                this.importDisplay = false;
            },
            exceltemplatesetImport(v_data) {
                this.importTemplateId = v_data['id'];
                this.importDisplay = true;
            },
            onCellEditComplete(event) {
                let {
                    data,
                    newValue,
                    field
                } = event;
                data[field] = newValue;
                if (field == 'schemaname') {
                    this.binddataNodes = ref();
                    this.getBindDataDict(data[field])
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
    }
</script>